import * as React from 'react';
import { Box, Stack } from '@mui/material';
import AppBar from './components/AppBar';
import Sidebar from './components/Sidebar';

import ComissionList from './pages/CRM/Comissions/ComissionList'
import ContactList from './pages/CRM/Contacts/ContactList'
import ContactDetail from './pages/CRM/Contacts/ContactDetail'
import DealList from './pages/CRM/Deals/DealList'
import GroupList from './pages/CRM/Groups/GroupList'
import GroupDetail from './pages/CRM/Groups/GroupDetail'
import LeadList from './pages/CRM/Leads/LeadList'
import OrderList from './pages/CRM/Orders/OrderList'
import PaymentList from './pages/CRM/Payments/PaymentList'
import QuoteList from './pages/CRM/Quotes/QuoteList'
import TaskList from './pages/CRM/Tasks/TaskList'

import LeadDetail from './pages/CRM/Leads/LeadDetail'
import LeadAdd from './pages/CRM/Leads/LeadAdd'

import ProductList from './pages/Services/Products/ProductList'
import ServiceList from './pages/Services/Services/ServiceList'
import ServiceDetail from './pages/Services/Services/ServiceDetail'
import StaffList from './pages/Services/Staffs/StaffList'
import StaffAbility from './pages/Services/Staffs/StaffAbility'


import CampaignList from './pages/Marketings/Campaigns/CampaignList'
import SmsSend from './pages/Marketings/SMS/SmsSend'
import SmsHistory from './pages/Marketings/SMS/SmsHistory'
import VoucherList from './pages/Marketings/Vouchers/VoucherList'

import AccountList from './pages/Management/Accounts/AccountList'
import PermissionList from './pages/Management/Permissions/PermissionList'
import PermissionDetail from './pages/Management/Permissions/PermissionDetail'
import Dashboard from './pages/Management/Reports/Dashboard'

import ReportDashboard from './pages/CRM/Report/ReportDashboard'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { useAtom } from 'jotai';
import { authAtom, servicessAtom, shopsAtom, sourcesAtom, tokenAtom, staffsAtom } from './actions';
import LoginPage from './pages/Auth/Login/Login';
import axios from 'axios';
import { API_BASE_URL } from './actions/api';
import { hasPermission } from './utils/utils';
import { toast } from 'react-toastify'

const drawerWidth = 180;

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const [token] = useAtom(tokenAtom)

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
};

export default function App() {
  const [token, setToken] = useAtom(tokenAtom)
  const [, setShops] = useAtom(shopsAtom)
  const [, setAuth] = useAtom(authAtom)
  const [, setSources] = useAtom(sourcesAtom)
  const [, setServices] = useAtom(servicessAtom)
  const [, setStaffs] = useAtom(staffsAtom)

  React.useEffect(() => {
    axios.defaults.baseURL = API_BASE_URL
  }, [])

  React.useEffect(() => {
    console.log({ token })
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`

      axios.get('/rest-auth/user/')
        .then((userRes) => {
          if (userRes && userRes.data) {
            setAuth(userRes.data)
            if (userRes.data.account_type !== 1 && hasPermission(userRes.data, ['crm_access'])) {
              setAuth(userRes.data)
            } else {
              toast.error('Tài khoản này không thể truy cập CRM')
              setToken(null)
            }
          }
        })
        .catch((error) => {
          console.log({ error })
        })

    }
  }, [token, setAuth, setToken])

  React.useEffect(() => {
    const getShops = () => {
      axios.get('/shops/shop/')
        .then((response) => {
          if (response && response.data) {
            setShops(response.data)
          }
        })
        .catch((error) => {
          console.log({ error })
        })
    }

    getShops()
  }, [setShops])

  React.useEffect(() => {
    const getSources = () => {
      axios.get('/accounts/sources/get_sources/')
        .then((response) => {
          if (response && response.data) {
            setSources(response.data)
          }
        })
        .catch((error) => {
          console.log({ error })
        })
    }

    getSources()
  }, [setSources])

  React.useEffect(() => {
    const getServices = () => {
      if (token) {
        axios.get('/services/service/get_services/')
          .then((response) => {
            if (response && response.data) {
              setServices(response.data)
            }
          })
          .catch((error) => {
            console.log({ error })
          })
      }
    }

    getServices()
  }, [setServices, token])

  React.useEffect(() => {
    const getStaffList = () => {
      if (token) {
        axios.get('/accounts/users/advisor_list/')
          .then((response) => {
            if (response && response.data) {
              let staffs = []
              response.data.map((item) => {
                if (hasPermission(item, ['crm_access']))
                  staffs.push(item)
              })
              setStaffs(staffs)
            }
          })
          .catch((error) => {
            console.log({ error })
          })
      }

    }

    getStaffList()
  }, [setStaffs, token])

  return (

      <Router>
        <Stack sx={{ flex: 1, overflow: 'hidden', padding: 0 }}>
          {token && <AppBar />}
          <Stack direction='row' component="main" sx={{ display: 'flex', flex: 1, overflow: 'hidden', padding: 0 }}>
            {token &&
              <Stack sx={{ overflow: 'hidden', width: { xs: 0, md: drawerWidth } }}>
                <Sidebar />
              </Stack>
            }
            <Stack
              sx={{ flex: 1, overflowY: 'scroll', padding: 0 }}
            >
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return (<Redirect to='/crm/report' />)
                  }}
                />
                <Route
                  exact
                  path="/crm"
                  render={() => {
                    return (<Redirect to='/crm/report' />)
                  }}
                />
                <Route
                  path="/login"
                  component={LoginPage}
                />
                <RestrictedRoute
                  path="/crm/report"
                  component={ReportDashboard}
                />
                <RestrictedRoute
                  path="/crm/comissions"
                  component={ComissionList}
                />
                <RestrictedRoute
                  path="/crm/contacts"
                  component={ContactList}
                />
                <RestrictedRoute
                  path="/crm/contact/:username"
                  component={ContactDetail}
                />
                <RestrictedRoute
                  path="/crm/deals"
                  component={DealList}
                />
                <RestrictedRoute
                  path="/crm/orders"
                  component={OrderList}
                />
                <RestrictedRoute
                  path="/crm/groups"
                  component={GroupList}
                />
                <RestrictedRoute
                  path="/crm/group/:id"
                  component={GroupDetail}
                />
                <RestrictedRoute
                  path="/crm/leads"
                  component={LeadList}
                />
                <RestrictedRoute
                  path="/crm/lead/new"
                  component={LeadAdd}
                />
                <RestrictedRoute
                  path="/crm/lead/:username/:id"
                  component={LeadDetail}
                />
                <RestrictedRoute
                  path="/crm/jobs"
                  component={StaffAbility}

                />
                <RestrictedRoute
                  path="/crm/payments"
                  component={PaymentList}

                />
                <RestrictedRoute
                  path="/crm/quotes"
                  component={QuoteList}
                />
                <RestrictedRoute
                  path="/crm/tasks"
                  component={TaskList}
                />
                <RestrictedRoute
                  path="/marketing/campaigns"
                  component={CampaignList}
                />
                <RestrictedRoute
                  path="/marketing/sms-send"
                  component={SmsSend}
                />
                <RestrictedRoute
                  path="/marketing/sms-history"
                  component={SmsHistory}
                />
                <RestrictedRoute
                  path="/marketing/vouchers"
                  component={VoucherList}
                />
                <RestrictedRoute
                  path="/management/accounts"
                  component={AccountList}
                />
                <RestrictedRoute
                  path="/management/permissions"
                  component={PermissionList}
                />
                <RestrictedRoute
                  path="/management/permission/:username"
                  component={PermissionDetail}
                />
                <RestrictedRoute
                  path="/management/dashboard"
                  component={Dashboard}
                />
                <RestrictedRoute
                  path="/app/products"
                  component={ProductList}
                />
                <RestrictedRoute
                  path="/app/services"
                  component={ServiceList}
                />
                <RestrictedRoute
                  path="/app/service/:id"
                  component={ServiceDetail}
                />
                <RestrictedRoute
                  path="/app/staffs"
                  component={StaffList}
                />
                <RestrictedRoute
                  path="/app/staff/:id"
                  component={StaffAbility}
                />
              </Switch>
            </Stack>
          </Stack>
        </Stack>

      </Router>
  );
}