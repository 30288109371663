import { Autocomplete, Box, FormControl, Icon, Input, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import ContactLeadItem from './components/ContactLeadItem';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import InfoIcon from '@mui/icons-material/Info'
import { useHistory } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, leadsAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { hasPermission, LEAD_STATUS_LIST } from '../../../utils/utils';
import { MultiSelect } from "react-multi-select-component";
import ContactLeadListItem from './components/ContactLeadListItem';
import { useLocation } from 'react-router-dom';
const grid = 8;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        zIndex: 1
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

// const [statusState] = useState([
//     { title: 'Chưa có tiến trình', description: 'Chưa có tiến trình', id: 0 },
//     { title: 'Telesale', description: 'Telesale đang liên hệ khách hàng', id: 1 },
//     { title: 'Khách quan tâm', description: 'Telesale đã liên hệ khách hàng, khách quan tâm tới dịch vụ và đồng ý đến nghe tư vấn', id: 2 },
//     { title: 'Đã đặt lịch tư vấn', description: 'Đã đặt lịch tư vấn trực tiếp tại cơ sở', id: 3 },
//     // { title: 'Khách mua liệu trình', description: 'Khách đã đồng ý mualiệu trình', id: 4 },
// ]);

const Screen = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const oldStatus = queryParams.get('status');

    const [total, setTotal] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [page, setPage] = useState(0)

    const [leads, setLeads] = useAtom(leadsAtom)
    const [token] = useAtom(tokenAtom)
    const history = useHistory()

    const [keyword, setKeyword] = useState('')
    const [staffs] = useAtom(staffsAtom)
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const authUser = useAtomValue(authAtom)
    const [status, setStatus] = useState(oldStatus ?? null)

    const onAddLead = () => {
        history.push(`/crm/lead/new`)
    }

    const refreshLeads = useCallback(() => {
        console.log({token})
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Token ${token}`
            
            axios.post('/accounts/leads/get_leads/', {
                offset: page * rowsPerPage,
                limit: rowsPerPage,
                staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                status: status,
                keyword
            })
                .then((response) => {
                    if (response && response.data) {
                        setLeads(response.data.leads)
                        setTotal(response.data.total)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
        }

    }, [token, page, rowsPerPage, selectedStaffs, keyword, status, setLeads])

    useEffect(() => {
        refreshLeads()
    }, [refreshLeads])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeKeyword = (e) => {
        setPage(0)
        setKeyword(e.target.value)
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, value: item.id }))

    if (!(authUser && hasPermission(authUser, ['write_lead_all', 'create_new_lead', 'view_lead', 'view_lead_all']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', background: '#eee' }}>
            <Stack width='95%'>
                <ActionsBar title='Danh sách Lead' subTitle={`Tìm thấy ${total} lead phù hợp`} addButtonTitle='Thêm Lead mới' onAddPress={onAddLead} />
                <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'flex-end', gap: '16px', width: '100%' }}>
                    <FormControl variant='standard'>
                        <InputLabel sx={{ fontSize: '12px' }}>Tên khách hàng, số điện thoại</InputLabel>
                        <Input
                            value={keyword} onChange={handleChangeKeyword}
                            size='small' style={{ width: '300px' }}
                            placeholder="Tên khách hàng, số điện thoại"
                        />
                    </FormControl>
                    <FormControl sx={{ width: '160px' }} variant='standard'>
                        <InputLabel sx={{ fontSize: '12px' }}>Trạng thái tiến trình</InputLabel>
                        <Select sx={{ fontSize: '12px' }}
                            defaultValue={null} value={status}
                            onChange={(e) => setStatus(e.target.value)} label='Trạng thái tiến trình'>
                            {
                                [
                                    { name: 'Tất cả', id: null },
                                    ...LEAD_STATUS_LIST
                                ].map((item) => {
                                    return (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        hasPermission(authUser, ['view_lead_all', 'write_lead']) && (
                            <Stack sx={{ width: '250px', zIndex: 20, fontSize: '12px' }}>
                                <MultiSelect
                                    options={staffOptions}
                                    value={selectedStaffs}
                                    onChange={setSelectedStaffs}
                                    labelledBy="Chọn nhân viên"
                                    overrideStrings={{
                                        "allItemsAreSelected": "Tất cả",
                                        "clearSearch": "Xoá",
                                        "clearSelected": "Bỏ chọn",
                                        "search": "Tìm kiếm",
                                        "selectAll": "Chọn tất cả",
                                        "selectAllFiltered": "Chọn tất cả",
                                        "selectSomeItems": "Lựa chọn nhân viên"
                                    }}
                                />
                            </Stack>
                        )
                    }
                </Stack>
                <div style={{ display: "flex", width: '100%', height: 'calc(100vh - 210px)', marginTop: '32px', gap: '16px' }}>
                    <Paper style={{ padding: '0px', width: '100%' }}>
                        <Stack direction='row' justifyContent='flex-end' spacing='16px'>
                            <TablePagination
                                rowsPerPageOptions={[20, 30, 40, 50]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Stack>
                        <TableContainer component={Paper} style={{ overflowY: 'scroll', width: '100%', overflowX: 'scroll', maxHeight: '60vh' }}>
                            <Table stickyHeader>
                                <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                    <TableRow>
                                        <StyledTableCell style={{ minWidth: '60px' }}>STT</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: '120px' }}>Họ và tên</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '120px' }}>Số điện thoại</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '120px' }}>Tiến trình hiện tại</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '120px' }}>Hạng khách hàng</StyledTableCell>
                                        {/* <StyledTableCell align="right" style={{ minWidth: '120px' }}>Loại khách hàng</StyledTableCell> */}
                                        <StyledTableCell align="right" style={{ minWidth: '130px' }}>Phân viên phụ trách</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '120px' }}>Nguồn khách hàng</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '100px', }}>Chi tiết nguồn</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '100px', }}>Tình trạng hẹn</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '100px', }}>Lý do từ chối</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '150px', }}>Thời gian cập nhật cuối</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '130px', }}>Thời gian tạo Lead</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '130px', }}>Thời gian ghi chú</StyledTableCell>
                                        <StyledTableCell align="right" style={{ minWidth: '150px', }}>Ghi chú</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leads.map((lead, index) => (
                                        <ContactLeadListItem index={index} lead={lead} key={`${index}-${lead.id.toString()}`} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </Stack>
        </div>
    )
}

export default Screen