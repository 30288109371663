import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom, tokenAtom } from '../actions';
const logo_image = require('../assets/images/logo_icon.png')

const components = [
    { title: 'Sale', path: '/crm/leads', rootPath: '/crm/', permission: 'crm_access' },
    // { title: 'Marketing', path: '/marketing/campaigns', rootPath: '/marketing/' },
    // { title: 'Dịch vụ', path: '/app/services', rootPath: '/app/' },
    { title: 'Quản lý', path: '/management/accounts', rootPath: '/management/', permission: 'management_access' },
]

const Appbar = () => {
    const history = useHistory()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [, setToken] = useAtom(tokenAtom)
    const [auth, setAuth] = useAtom(authAtom)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        setToken(undefined)
        setAuth(undefined)
    }

    if (location.pathname.indexOf(`/login`) >= 0) return null

    return (
        <Box sx={{ height: { xs: '40px', md: '60px', zIndex: '100' }, 
        display: 'flex', 
        width: '100vw',
        alignItems: 'center', justifyContent: 'center', backgroundColor: 'black' }}>
            {/* <Box sx={{ color: 'white', display: 'flex', marginLeft: '16px' }}>
                {
                    components.map((item, index) => {
                        let canOpen = false
                        if (item.permission === 'all') {
                            canOpen = true
                        } else {
                            if (auth && auth.permissions && auth.permissions.indexOf(item.permission) >= 0) {
                                canOpen = true
                            }
                        }

                        if (canOpen) {
                            return (
                                <Box
                                    onClick={() => history.push(item.path)}
                                    key={item.title}
                                    sx={{ cursor: 'pointer', height: 35, textAlign: 'center', color: 'white', width: 120, borderBottom: location.pathname.indexOf(item.rootPath) >= 0 ? '4px solid white' : 'none' }}>
                                    <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>{item.title}</span>
                                </Box>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </Box> */}
            <Box sx={{ height: { xs: '25px', md: '40px' }, width: { xs: '50px', md: '80px' } }}>
                <img alt='' src={logo_image} style={{width: '100%', height: 'auto'}}/>
            </Box>
            {/* <Box sx={{ display: { md: 'flex', color: 'white' }, marginRight: '16px' }}>
                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    color="inherit"
                    onClick={handleMenu}
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
                </Menu>
            </Box> */}
        </Box>
    );
}

export default Appbar