import React from 'react'
import { Box, Card, Divider, Stack, styled, TableCell, TableRow } from '@mui/material'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { CUSTOMER_PRIOTIES, LEAD_STATUS_LIST_MAP } from '../../../../utils/utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ContactLeadListItem = ({ lead, index }) => {
    const history = useHistory()
    const openDetail = () => {
        history.push(`/crm/lead/${lead.user.username}/${lead.id}`)
    }

    return (
        <StyledTableRow onClick={openDetail}>
            <TableCell style={{ minWidth: '60px' }}>{index + 1}</TableCell>
            <TableCell style={{ minWidth: '120px' }}>{lead.user.full_name}</TableCell>
            <TableCell align="right" style={{ minWidth: '120px' }}>{lead.user.username}</TableCell>
            <TableCell align="right" style={{ minWidth: '120px' }}>{LEAD_STATUS_LIST_MAP[lead.status]}</TableCell>
            <TableCell align="right" style={{ minWidth: '120px' }}>{CUSTOMER_PRIOTIES[lead.user.customer_priority]}</TableCell>
            {/* <TableCell align="right" style={{ minWidth: '120px' }}>Loại khách hàng</TableCell> */}
            <TableCell align="right" style={{ minWidth: '130px' }}>{lead.sale1?.full_name}</TableCell>
            <TableCell align="right" style={{ minWidth: '120px' }}>{lead.source?.name}</TableCell>
            <TableCell align="right" style={{ minWidth: '100px', }}>Chi tiết nguồn</TableCell>
            <TableCell align="right" style={{ minWidth: '100px', }}>{lead.zoom_status}</TableCell>
            <TableCell align="right" style={{ minWidth: '100px', }}>{lead.zoom_deny_reason}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px', }}>{dayjs(lead.updated_time).format('HH:mm DD/MM/YYYY')}</TableCell>
            <TableCell align="right" style={{ minWidth: '130px', }}>{dayjs(lead.created_time).format('HH:mm DD/MM/YYYY')}</TableCell>
            <TableCell align="right" style={{ minWidth: '130px', }}>{lead.note_time ? dayjs(lead.note_time).format('HH:mm DD/MM/YYYY') : ''}</TableCell>
            <TableCell align="right" style={{ minWidth: '150px', }}>{lead.note}</TableCell>
        </StyledTableRow>
    )
}

export default ContactLeadListItem