import { Autocomplete, Box, Divider, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useState } from 'react'
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import { Modal, Button } from '@mui/material'
import { useAtom } from 'jotai';
import { authAtom, leadsAtom, servicessAtom, shopsAtom, sourcesAtom } from '../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import AddServiceModal from './modals/AddServiceModal';
import ServiceSuggestItem from './components/ServiceSuggestItem';
import axios from 'axios';
import { hasPermission } from '../../../utils/utils';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}



const Screen = () => {
    const history = useHistory()
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)

    const [customer, setCustomer] = useState({
        status: 0,
        username: '',
        email: '',
        note: '',
        shop: shops && shops.length >= 1 ? shops[0].id : undefined,
        source: sources && sources.length > 0 ? sources[0].id : null
    })

    const handleSave = async () => {
        if (customer.username && customer.full_name && customer.shop && customer.shop) {
            toast.loading()
            try {
                const checkRes = await axios.post('/accounts/leads/check_phone_exist/', {
                    phone: customer.username
                })

                if (checkRes && checkRes.data && !checkRes.data.exist) {
                    const result = await axios.post('/rest-auth/registration/', {
                        username: customer.username,
                        full_name: customer.full_name,
                        password1: 'anhdior2015',
                        password2: 'anhdior2015'
                    })
                }

                const response = await axios.post('/accounts/leads/add_lead/', {
                    username: customer.username,
                    full_name: customer.full_name,
                    email: customer.email,
                    note: customer.note,
                    shop: customer.shop,
                    telesale: auth.id,
                    source: customer.source
                })

                if (response && response.data && response.data.id) {
                    toast.success('Tạo lead mới thành công')
                    history.push('/crm/leads')
                }
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message)
                } else if(error && error.response && error.response.data && error.response.data.username) {
                    toast.error(error.response.data.username.join(', '))
                } else {
                    toast.error('Tạo lead không thành công')
                }
            }

        } else {
            toast.error('Điền thiếu thông tin lead')
        }
    }

    if(!(auth && hasPermission(auth, ['write_lead_all', 'create_new_lead']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', height: '100vh', background: '#eeeeee', padding: '32px 0px' }}>
            <ActionsBar title='Tạo lead mới' />
            <div style={{ display: "flex", justifyContent: 'space-between', marginTop: '24px', marginLeft: '32px', marginRight: '32px' }}>
                <div style={{ width: '48%' }}>
                    <Stack style={{ background: 'white', borderRadius: 4, }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Thông tin cơ bản</h4>
                            <TextField
                                label="Số điện thoại"
                                variant="standard" placeholder='Số điện thoại' value={customer.username ?? ''}
                                onChange={(e) => setCustomer((old) => ({ ...old, username: e.target.value }))}
                            />

                            <TextField
                                label="Tên khách hàng"
                                variant="standard" placeholder='Tên khách hàng' value={customer.full_name ?? ''}
                                onChange={(e) => setCustomer((old) => ({ ...old, full_name: e.target.value }))}
                            />

                            <TextField
                                label="Email"
                                variant="standard" placeholder='Email' value={customer.email ?? ''}
                                onChange={(e) => setCustomer((old) => ({ ...old, email: e.target.value }))}
                            />

                            <TextField
                                id="outlined-textarea"
                                variant="standard"
                                label="Ghi chú"
                                multiline
                                rows={4}
                                value={customer.note ?? ''}
                                onChange={(e) => setCustomer((old) => ({ ...old, note: e.target.value }))}
                            />
                        </Stack>
                    </Stack>
                </div>
                <div style={{ width: '48%', overflowX: 'scroll', background: 'white', borderRadius: 4, }}>
                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Nguồn khách</h4>
                            <Stack spacing={1}>
                                <FormControl>
                                    <InputLabel id="shop-select-label">Cửa hàng</InputLabel>
                                    <Select
                                        labelId="shop-select-label"
                                        id="shop-select-select"
                                        value={customer.shop}
                                        label="Cửa hàng"
                                        onChange={(e) => setCustomer(old => ({ ...old, shop: e.target.value }))}
                                    >
                                        {(shops ?? []).map((item) => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>

                            <Stack spacing={1}>
                                <FormControl>
                                    <InputLabel id="source-select-label" >Nguồn khách</InputLabel>
                                    <Select
                                        labelId="source-select-label"
                                        id="source-select"
                                        value={customer.source}
                                        label="Nguồn khách"
                                        onChange={(e) => setCustomer(old => ({ ...old, source: e.target.value }))}
                                    >
                                        {(sources ?? []).map((item) => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
            </div>

            <Stack alignItems='center' marginTop='20px'>
                <Button onClick={handleSave} variant='contained' style={{ width: '300px' }}>
                    Tạo LEAD mới
                </Button>
            </Stack>
        </div>
    )
}

export default Screen