import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs';
import { PAYMENT_METHOD, convertMoney, getOrderId } from '../../../../utils/utils';
import { useAtomValue } from 'jotai';
import { authAtom } from '../../../../actions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const StaffReportTotalItem = ({ staffs }) => {
    let totalLeads = 0
    let completedLeads = 0
    let orders = 0
    let totalPrices = 0
    let messages = 0
    let comissions = 0

    console.log({staffs})

    try {
        (staffs ?? []).forEach(staff => {
            totalLeads += staff.total_lead ?? 0
            completedLeads += staff.completed_lead ?? 0
            orders += staff.order ?? 0
            totalPrices += staff.totalPrice ?? 0
            messages += staff.message ?? 0
            comissions += staff.comission ?? 0
        })
    } catch (error) {

    }


    return (
        <TableRow sx={{position: 'sticky', top: '50px', zIndex: 1}}>
            <StyledTableCell sx={{color: 'white'}}>{`Tổng`}</StyledTableCell>
            <StyledTableCell sx={{color: 'white'}} align="right">{`${totalLeads}`}</StyledTableCell>
            <StyledTableCell sx={{color: 'white'}} align="right">{`${completedLeads}`}</StyledTableCell>
            <StyledTableCell sx={{color: 'white'}} align="right">{orders}</StyledTableCell>
            <StyledTableCell sx={{color: 'white'}} align="right">{convertMoney(totalPrices, true)}</StyledTableCell>
            <StyledTableCell sx={{color: 'white'}} align="right">{messages}</StyledTableCell>
            <StyledTableCell sx={{color: 'white'}} align="right">{convertMoney(comissions, true)}</StyledTableCell>
        </TableRow>
    )
}

export default StaffReportTotalItem