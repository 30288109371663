import { FormControl, Box, Button, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Table, TablePagination, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { Download, } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ComissionItem from './components/ComissionItem';
import { styled } from '@mui/material/styles'
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { toast } from 'react-toastify';
import DateRangeModal from '../Payments/modals/DateRangeModal';
import dayjs from 'dayjs';
import AddComissionModal from '../Leads/modals/AddComissionModal';
import { hasPermission, showConfirmationToast, convertMoney, getOrderId } from '../../../utils/utils';
import * as XLSX from 'xlsx'
import { MultiSelect } from "react-multi-select-component";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const Screen = () => {
    const [total, setTotal] = useState(0)
    const [comissions, setComissions] = useState([])
    const [token] = useAtom(tokenAtom)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [keyword, setKeyword] = useState('')
    const [selectedStaffs, setSelectedStaffs] = useState([])
    const [staffs] = useAtom(staffsAtom)
    const [changeComissionModal, setChangeComissionModal] = useState(null)
    const [dateRangeModal, setDateRangeModal] = useState(false)
    const [startDate, setStartDate] = useState(dayjs().subtract(30, 'days').format())
    const [endDate, setEndDate] = useState(dayjs().format())
    const authUser = useAtomValue(authAtom)
    const toastId = useRef(null)

    const getComissionList = useCallback(() => {
        console.log({token})
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Token ${token}`
            
            axios.post('/booking/comissions/comission_list/', {
                offset: page * rowsPerPage, limit: rowsPerPage,
                keyword: keyword.replace(/#/g, ''), staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                startDate: dayjs(startDate).startOf('day').format(), endDate: dayjs(endDate).endOf('day').format()
            }, {})
                .then((response) => {
                    if (response && response.data) {
                        setComissions(response.data.comissions)
                        setTotal(response.data.total)
                    } else {
                        setComissions([])
                        setTotal(0)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    setComissions([])
                    setTotal(0)
                })
        }
    }, [token, page, rowsPerPage, keyword, selectedStaffs, startDate, endDate])

    useEffect(() => {
        getComissionList()
    }, [getComissionList])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event.target.value)
        setPage(0)
    }

    const onChangeComission = ({ payment_id, sale1, sale2, telesale }) => {
        axios.post('/booking/comissions/update_comission/', {
            payment_id, sale1, sale2, telesale
        })
            .then((response) => {
                if (response && response.data) {
                    getComissionList()
                    toast.success('Xác nhận thành công')
                    setChangeComissionModal(null)
                } else {
                    toast.error('Cập nhật thất bại')
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onChangeRange = (range) => {
        setStartDate(range.startDate)
        setEndDate(range.endDate)
        setDateRangeModal(false)
    }

    const onDownload = () => {
        showConfirmationToast(`Chắc chắn tải về danh sách hoa hồng từ ${dayjs(startDate).format('DD/MM/YYYY')} đến ${dayjs(endDate).format('DD/MM/YYYY')}?`,
            () => {
                setTimeout(() => {
                    toastId.current = toast.loading("Đang tải dữ liệu")
                    axios.post('/booking/comissions/download_comission_list/', {
                        keyword: keyword.replace(/#/g, ''), staffs: selectedStaffs.length > 0 ? selectedStaffs.map((item) => item.value) : null,
                        startDate: dayjs(startDate).startOf('day').format(), endDate: dayjs(endDate).endOf('day').format()
                    }, {})
                        .then((response) => {
                            try {
                                if (response && response.data) {
                                    const report = response.data.map((item) => {
                                        return ({
                                            'order': getOrderId(item.lead_order.id),
                                            'full_name': item.lead_order.user.full_name,
                                            'username': item.lead_order.user.username,
                                            'totalPrice': item.totalPrice ?? 0,
                                            'date': dayjs(item.lead_order.order_date).format('HH:mm:ss DD/MM/YYYY'),
                                            'sale1': `${item.lead_order.sale1 ? item.lead_order.sale1.full_name : ''}`,
                                            'sale1Comission': item.sale1 ?? 0,
                                            'sale2': `${item.lead_order.sale2 ? item.lead_order.sale2.full_name : ''}`,
                                            'sale2Comission': item.sale2 ?? 0,
                                            'telesale': `${item.lead_order.telesale ? item.lead_order.telesale.full_name : ''}`,
                                            'telesaleComission': item.telesale ?? 0,
                                        })
                                    })
                                    const worksheet = XLSX.utils.json_to_sheet(report, { origin: 'A2' });
                                    const headers = ['Đơn hàng', "Khách hàng", 'Số điện thoại', 'Giá trị đơn', 'Ngày', 'Sale chính', 'Hoa hồng sale chính', 'Sale phụ', 'Hoa hồng sale phụ', 'Telesale', 'Hoa hồng telesale']
                                    worksheet['!merges'] = [
                                        { s: { r: 0, c: 0 }, e: { r: 0, c: headers.length } },
                                    ]
                                    XLSX.utils.sheet_add_aoa(worksheet, [[`Danh sách hoa hồng từ ${dayjs(startDate).format('DD/MM/YYYY')} đến ${dayjs(endDate).format('DD/MM/YYYY')}`]], { origin: "A1" });
                                    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A2" });
                                    const workbook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
                                    XLSX.writeFile(workbook, `Danh sách hoa hồng.xlsx`);

                                    toast.dismiss(toastId.current)
                                    toast.success('Tải về thành công')
                                }
                            } catch (error) {
                                toast.dismiss(toastId.current)
                                toast.error('Lỗi tải về')
                            }
                        })
                        .catch((error) => {
                            console.log({ error })
                            toast.dismiss(toastId.current)
                            toast.error('Lỗi tải về')
                        })
                }, 500);
            }, () => { })
    }

    const staffOptions = staffs.map((item) => ({ label: item.full_name, value: item.id }))

    if (!(authUser && hasPermission(authUser, ['view_comission', 'view_comission_all', 'write_comission']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <Stack sx={{ flex: 1, width: '100%', padding: 0 }}>
            <ActionsBar sx={{ width: '100%'}} title='Danh sách hoa hồng' subTitle={`Tìm thấy: ${total} thanh toán`} />
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                <Box sx={{ flexDirection: {xs: 'column', md: 'row'}, display: 'flex', gap: '16px', alignItems: 'enter', justifyContent: 'space-between', marginLeft: '24px', marginRight: '24px' , flex: 1 }}>
                    <Stack gap={'12px'}>
                        <FormControl sx={{ width: '100%' }} variant='standard'>
                            <InputLabel sx={{ fontSize: '12px' }}>Tên khách hàng, số điện thoại, đơn hàng</InputLabel>
                            <Input
                                value={keyword} onChange={handleChangeKeyword}
                                size='small' sx={{ width: '300px' }}
                                placeholder="Tên khách hàng, số điện thoại, đơn hàng"
                            />
                        </FormControl>
                    </Stack>

                    <Stack direction='row' spacing='8px'>
                        <Stack sx={{ width: '160px', cursor: 'pointer', border: '0.5px solid #cccccc', borderRadius: '5px' }} variant='standard' onClick={() => setDateRangeModal(true)}>
                            <InputLabel sx={{ fontSize: '10px' }}>Thời gian</InputLabel>
                            <Typography sx={{ fontSize: '12px' }}>{`${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`}</Typography>
                        </Stack>
                        <Stack direction='row' sx={{ marginRight: '16px' }}>
                            {
                                hasPermission(authUser, ['view_comission_all', 'write_comission']) && (
                                    <Stack sx={{ zIndex: 20, fontSize: '12px' }}>
                                        <MultiSelect
                                            options={staffOptions}
                                            value={selectedStaffs}
                                            onChange={setSelectedStaffs}
                                            labelledBy="Chọn nhân viên"
                                            overrideStrings={{
                                                "allItemsAreSelected": "Tất cả",
                                                "clearSearch": "Xoá",
                                                "clearSelected": "Bỏ chọn",
                                                "search": "Tìm kiếm",
                                                "selectAll": "Chọn tất cả",
                                                "selectAllFiltered": "Chọn tất cả",
                                                "selectSomeItems": "Lựa chọn nhân viên"
                                            }}
                                        />
                                    </Stack>
                                )
                            }
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <Box sx={{ display: "flex", width: '100%', marginTop: '24px', padding: '0px' }}>
                <Stack sx={{ width: '100%', padding: '0px', margin: '0px 16px' }}>
                    <Stack direction='row' justifyContent='flex-end' spacing='16px' sx={{width: '100%',}}>
                        <TablePagination
                            rowsPerPageOptions={[20, 30, 40, 50]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        {hasPermission(authUser, ['export_report_comission']) && <Button onClick={onDownload}><Download /></Button>}
                    </Stack>
                    <TableContainer component={Paper} sx={{ overflowY: 'scroll',  maxWidth: '100%', overflowX: 'scroll', maxHeight: '60vh' }}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead >
                                <TableRow>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Đơn hàng</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Khách hàng</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>SĐT KH</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Số tiền thanh toán</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Ngày</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Sale 1</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Sale 2</StyledTableCell>
                                    <StyledTableCell align="right" sx={{ minWidth: {md: '120px'} }}>Telesale</StyledTableCell>
                                    <StyledTableCell align="right" sx={{minWidth: {md: '120px'}  }}></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {comissions.map((comission) => (
                                    <ComissionItem
                                        auth={authUser}
                                        key={comission.id.toString()}
                                        comission={comission}
                                        onChangeComission={() => setChangeComissionModal(comission)}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
                {changeComissionModal !== null &&
                    <AddComissionModal
                        open={changeComissionModal !== null}
                        onChangeComission={onChangeComission}
                        payment={changeComissionModal}
                        handleClose={() => {
                            setChangeComissionModal(null)
                        }}
                    />
                }
                <DateRangeModal
                    open={dateRangeModal}
                    startDate={startDate}
                    endDate={endDate}
                    onConfirm={onChangeRange}
                    handleClose={() => {
                        setDateRangeModal(false)
                    }}
                />
            </Box>
        </Stack>
    )
}

export default Screen