import { Autocomplete, Badge, Box, Button, Divider, Checkbox, Input, InputLabel, MenuItem, OutlinedInput, Select, Stack, Step, StepLabel, Stepper, Tab, Table, Tabs } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom, leadsAtom, servicessAtom, shopsAtom, sourcesAtom, staffsAtom, tokenAtom } from '../../../actions/index'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import AddServiceModal from './modals/AddServiceModal';
import ServiceSuggestItem from './components/ServiceSuggestItem';
import ScheduleItem from './components/ScheduleItem';
import TransactionItem from './components/TransactionItem';
import ConnectionItem from './components/ConnectionItem';
import OrderItem from './components/OrderItem';
import AddScheduleModal from './modals/AddScheduleModal';
import AddCommunicationModal from './modals/AddConnectionModal';
import AddOrderModal from './modals/AddOrderModal';
import AddPaymentModal from './modals/AddPaymentModal';
import AddComissionModal from './modals/AddComissionModal';
import { hasPermission, LEAD_STATUS_LIST, showConfirmationToast } from '../../../utils/utils';
import ChangeOrderDateModal from './modals/ChangeOrderDateModal';
import UpdateOrderModal from './modals/UpdateOrderModal';
import dayjs from 'dayjs';

// (0, 'Chưa có tiến trình'),
// # (1, 'Telesale chăm sóc'),
// # (2, 'Khách quan tâm - chuyển sale'),
// # (3, 'Khách đặt lịch tư vấn'),
// # (4, 'Khách mua liệu trình'),
// (1, 'Khách hàng tiềm năng mức 1'),
// (2, 'Khách hàng tiềm năng mức 2'),
// (3, 'Booking'),
// (5, 'Đến phòng khám'),
// (6, 'Đặt cọc'),
// (7, 'Full tiền'),
// (8, 'Xin lỗi'),
// (9, 'Không đến phòng khám'),
// (10, 'BONG'),

const steps = [
    'Chưa có tiến trình',
    'Telesale',
    'Khách quan tâm',
    'Đã đặt lịch tư vấn',
    'Khách mua liệu trình',
];

const customerPriorities = [
    {
        id: 1, name: 'Thường',
    },
    {
        id: 2, name: 'Đồng',
    },
    {
        id: 3, name: 'Bạc',
    },
    {
        id: 4, name: 'Vàng',
    },
    {
        id: 5, name: 'Kim Cương',
    }
];

const customerTypes = [
    {id: 1, name: 'Khách hàng mới'},
    {id: 2, name: 'Khách hàng cũ'},
]

const serviceTypes = [
]

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const Screen = () => {
    const history = useHistory()
    const { username: lead_username, id: lead_id } = useParams()
    const [auth] = useAtom(authAtom)
    const [shops] = useAtom(shopsAtom)
    const [sources] = useAtom(sourcesAtom)
    const [services] = useAtom(servicessAtom)
    const [staffs] = useAtom(staffsAtom)
    const [token] = useAtom(tokenAtom)

    const [lead, setLead] = useState({})
    const [shop, setShop] = useState(-1)
    const [source, setSource] = useState(-1)
    const [leadServices, setLeadServices] = useState([])
    const [leadTransactions, setLeadTransactions] = useState([])
    const [leadOrders, setLeadOrders] = useState([])

    const [selectedTab, setSelectedTab] = React.useState(0);

    const [addServiceModal, setAddServiceModal] = useState(false)
    const [addScheduleModal, setAddScheduleModal] = useState(false)
    const [addConnectionModal, setAddConnectionModal] = useState(false)
    const [addOrderModal, setAddOrderModal] = useState(false)
    const [addPaymentModal, setShowAddPaymentModal] = useState(null)
    const [changeComissionModal, setShowChangeComissionModal] = useState(null)
    const [changeOrderDateModal, setChangeOrderDateModal] = useState(null)
    const [updateOrderModal, setUpdateOrderModal] = useState(null)

    const [orderServices, setOrderServices] = useState([])

    const getLeadDetail = useCallback(() => {
        if (token)
            axios.post('/accounts/leads/get_lead/', {
                username: lead_username, id: lead_id
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setLead(res.data)

                        if (res.data.shop)
                            setShop(res.data.shop.id)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    if (error && error.response && error.response.data && error.response.data.message)
                        toast.error(error.response.data.message)
                    else
                        toast.error('Không tìm thấy thông tin lead')
                })
    }, [lead_username, lead_id, token])

    const getLeadServices = useCallback(() => {
        if (token)
            axios.post('/accounts/leads/get_lead_services/', {
                username: lead_username, id: lead_id
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setLeadServices(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
    }, [token, lead_username, lead_id])

    const getLeadSources = useCallback(() => {
        if (token)
            axios.post('/accounts/leads/get_lead_sources/', {
                username: lead_username, id: lead_id
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    console.log({ res })
                    if (res && res.data && res.data.source) {
                        setSource(res.data.source.id)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
    }, [token, lead_username, lead_id])

    const getLeadTransactions = useCallback(() => {
        if (token)
            axios.post('/accounts/leads/get_lead_transactions/', {
                username: lead_username, id: lead_id
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setLeadTransactions(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
    }, [token, lead_username, lead_id])

    const getOrders = useCallback(() => {
        if (token) {
            axios.post('/booking/lead_order/lead_orders/', {
                lead_id
            }, { headers: { Authorization: `Token ${token}` } })
                .then(res => {
                    if (res && res.data) {
                        setLeadOrders(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }, [token, lead_id])

    useEffect(() => {
        getLeadDetail()
        getLeadServices()
        getLeadTransactions()
        getLeadSources()
        getOrders()
    }, [getLeadDetail, getLeadServices, getLeadTransactions, getLeadSources, getOrders])

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleSave = () => {
        axios.post('/accounts/leads/update_lead/', {
            id: lead.id,
            note: lead.note,
            email: lead.email,
        },)
            .then(() => {
                getLeadDetail()
                toast.success('Cập nhật thành công')
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const setForceAssign = (checked) => {
        axios.post('/accounts/leads/update_lead/', {
            id: lead.id,
            force_assign: checked
        },)
            .then(() => {
                getLeadDetail()
                toast.success('Cập nhật thành công')
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const updateStatus = (newStatus, note) => {
        // if (lead.status === 1 && newStatus === 2) {
        //     if (leadServices.length === 0) {
        //         toast.error('Chưa thêm dịch vụ khách quan tâm')
        //         return
        //     }
        // }
        // if (lead.status === 2 && newStatus === 3) {
        //     if (leadTransactions.filter((item) => item.type === 3).length === 0) {
        //         toast.error('Chưa có lịch gặp khách')
        //         return
        //     }
        // }

        // if (lead.status === 3 && newStatus === 4) {
        //     if (leadOrders.length === 0) {
        //         toast.error('Chưa tạo đơn hàng')
        //         return
        //     }
        // }

        try {
            axios.post('/accounts/leads/update_lead_status/', {
                id: lead.id,
                status: newStatus,
                note: note
            }, {
                headers: { Authorization: `Token ${token}` }
            })
                .then((res) => {
                    console.log({ res })

                    if (res && res.data) {
                        setLead(res.data)
                    }
                })
                .catch((error) => {
                    console.log({ error })
                    if (error && error.response && error.response.data && error.response.data.message) {
                        toast.error(error.response.data.message)
                    } else {
                        if (error && error.response && error.response.data && error.response.data.message)
                            toast.error(error.response.data.message)
                        else
                            toast.error('Cập nhật thất bại')
                    }

                })
        } catch (error) {
            console.log({ error })
        }
    }

    const onChangeShop = (e) => {
        axios.post('/accounts/leads/update_lead/', {
            id: lead.id,
            shop: e.target.value
        },)
            .then((res) => {
                if (res && res.data) {
                    setLead(res.data)
                }

                if (res && res.data && res.data.shop)
                    setShop(res.data.shop.id)
            })
            .catch((error) => {
                console.log({ error })
            })
    }

    const onChangeSource = (e) => {
        if (lead && lead.user) {
            axios.post('/accounts/leads/update_lead_sources/', {
                user_id: lead.user.id,
                source_id: e.target.value
            })
                .then((data) => {
                    getLeadSources()
                })
                .catch((error) => {
                    console.log({ error })
                })
        }
    }

    const onAddService = (item) => {
        setAddServiceModal(false)

        const filter = leadServices.filter((s) => s.service && s.service.id === item.service.id)
        if (filter && filter.length > 0) {
            toast.error('Đã có liệu trình này')
        } else {
            setLeadServices((old) => [...old, item])
            axios.post('/accounts/leads/add_lead_service/', {
                id: lead.id,
                service: item.service.id,
                note: item.note,
                service_name: item.service_name
            })
                .then((data) => {
                    console.log({ data })
                })
                .catch((error) => {
                    console.log({ error })
                })
        }

    }

    const onRemoveService = (item, index) => {
        setLeadServices((old) => {
            const newArr = [...old]
            newArr.splice(index, 1)
            return newArr
        })
        axios.post('/accounts/leads/remove_lead_service/', {
            lead_id: lead.id,
            lead_service: item.id
        })
            .then((data) => {
                console.log({ data })
            })
            .catch((error) => {
                console.log({ error })
            })
    }

    const onAddSchedule = ({ id, time, note }) => {
        setAddScheduleModal(false)
        axios.post('/accounts/leads/add_lead_schedule/', {
            lead_id: lead.id,
            schedule_time: time,
            note: note,
            schedule_id: id
        })
            .then((data) => {
                console.log({ data })
                getLeadTransactions()
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Thêm lịch đặt chưa thành công')
            })
    }

    const onAddConnection = ({ id, time, note }) => {
        setAddConnectionModal(false)
        axios.post('/accounts/leads/add_lead_message/', {
            lead_id: lead.id,
            schedule_time: time,
            note: note,
            schedule_id: id
        })
            .then((data) => {
                console.log({ data })
                getLeadTransactions()
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Thêm thông tin liên lạc chưa thành công')
            })
    }

    const onCreateOrder = (items) => {
        const service_orders = items.map((item) => {
            return ({
                service: item.service.id,
                total_time: item.amount,
                price: item.price,
                note: item.note ?? '',
                service_name: item.service_name,
                orderId: item.orderId
            })
        })


        axios.post('/booking/lead_order/check_valid_order/', {
            items: service_orders,
            lead_id: lead.id
        })
            .then((data) => {
                createOrder(service_orders)
            })
            .catch((error) => {
                console.log({ error })
                let errorMessage = ""
                if (error && error.response && error.response.data && error.response.data.message)
                    errorMessage = error.response.data.message
                else
                    errorMessage = "Có lỗi khi kiểm tra các liệu trình, vẫn thực hiện thêm liệu trình?"

                showConfirmationToast(errorMessage, () => {
                    createOrder(service_orders)
                }, () => {

                })
            })
    }

    const createOrder = (service_orders) => {
        axios.post('/booking/lead_order/create_order/', {
            items: service_orders,
            lead_id: lead.id
        })
            .then((data) => {
                console.log({ data })
                setAddOrderModal(false)
                toast.success('Tạo đơn hàng thành công')
                getOrders()
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Tạo đơn hàng thất bại')
            })
    }

    const onCancelPayment = (order, payment) => {

        showConfirmationToast('Xác nhận chắc chắn muốn huỷ tất cả thanh toán của đơn hàng này?', () => {
            axios.post('/booking/lead_order/cancel_order_payments/', {
                lead_order_id: order.id,
                payment_id: payment.id
            })
                .then((data) => {
                    console.log({ data })
                    getOrders()
                    toast.success('Huỷ thanh toán thành công')
                })
                .catch((error) => {
                    console.log({ error })
                    if (error && error.response && error.response.data && error.response.data.message)
                        toast.error(error.response.data.message)
                    else
                        toast.error('Huỷ thanh toán thất bại')
                })
        }, () => {

        })
    }

    const onCancelOrder = (order) => {

        showConfirmationToast('Xác nhận chắc chắn muốn huỷ đơn hàng?', () => {
            axios.post('/booking/lead_order/cancel_orders/', {
                lead_order_id: order.id
            })
                .then((data) => {
                    console.log({ data })
                    getOrders()
                    toast.success('Huỷ đơn hàng thành công')
                })
                .catch((error) => {
                    console.log({ error })
                    if (error && error.response && error.response.data && error.response.data.message)
                        toast.error(error.response.data.message)
                    else
                        toast.error('Huỷ đơn hàng thất bại')
                })
        }, () => {

        })
    }

    const onChangeSale1 = (e) => {
        axios.post('/accounts/leads/update_lead_sale/', {
            id: lead_id, sale1: e.target.value
        })
            .then((data) => {
                console.log({ data })
                getLeadDetail()
                toast.success('Cập nhật thành công')
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onChangeSale2 = (e) => {
        axios.post('/accounts/leads/update_lead_sale/', {
            id: lead_id, sale2: e.target.value
        })
            .then((data) => {
                console.log({ data })
                getLeadDetail()
                toast.success('Cập nhật thành công')
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onChangeTelesale = (e) => {
        axios.post('/accounts/leads/update_lead_sale/', {
            id: lead_id, telesale: e.target.value
        })
            .then((data) => {
                console.log({ data })
                getLeadDetail()
                toast.success('Cập nhật thành công')
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onAddPayment = (payment) => {
        axios.post('/booking/payments/create_payment/', {
            lead_order: payment.lead_order, payment_date: payment.payment_date,
            amount: payment.amount, note: payment.note,
            payment_type: payment.payment_type, payment_method: payment.payment_method
        })
            .then((data) => {
                console.log({ data })
                getOrders()
                setShowAddPaymentModal(null)
                toast.success('Thêm thông tin thanh toán thành công')
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Thanh toán thất bại')
            })
    }

    const onChangeComission = ({ payment_id, sale1, sale2, telesale }) => {
        axios.post('/booking/comissions/update_comission/', {
            payment_id, sale1, sale2, telesale
        })
            .then((response) => {
                if (response && response.data) {
                    getOrders()
                    toast.success('Xác nhận thành công')
                    setShowChangeComissionModal(null)
                } else {
                    toast.error('Cập nhật thất bại')
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const onChangeOrderSale = (lead_order_id, sale_type, user_id) => {
        axios.post('/booking/lead_order/update_lead_order/', {
            lead_order_id, sale_type, user_id
        })
            .then((data) => {
                console.log({ data })
                getOrders()
                toast.success('Cập nhật thành công')
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const updateOrderItem = (order) => {
        const body = {
            total_time: order.amount,
            price: order.price,
            note: order.note ?? '',
            service_name: order.service_name,
            order_id: order.order_id
        }
        axios.post('/booking/lead_order/update_order_item/', body)
            .then((data) => {
                console.log({ data })
                getOrders()
                toast.success('Cập nhật thành công')
                setUpdateOrderModal(null)
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })
    }

    const changeOrderDate = ({ lead_order_id, order_date }) => {
        axios.post('/booking/lead_order/update_lead_order_time/', {
            lead_order_id, order_date
        })
            .then((data) => {
                console.log({ data })
                getOrders()
                toast.success('Cập nhật thành công')
                setChangeOrderDateModal(null)
            })
            .catch((error) => {
                console.log({ error })
                if (error && error.response && error.response.data && error.response.data.message)
                    toast.error(error.response.data.message)
                else
                    toast.error('Cập nhật thất bại')
            })

    }

    const onChangeCustomerPriority = () => {

    }

    const onChangeServiceType = () => {

    }

    const onChangeCustomerType = () => {

    }

    const openContact = () => {
        history.push(`/crm/contact/${lead_username}`)
    }

    if (!(auth && hasPermission(auth, ['write_lead_all', 'create_new_lead', 'view_lead', 'view_lead_all']))) {
        return <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px' }}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%', background: '#eeeeee', padding: '16px 0px' }}>
            <ActionsBar title='Chi tiết thông tin LEAD' />
            {/* <Stepper activeStep={lead ? lead.status : 0} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper> */}

            <div style={{ display: "flex", flex: 1, justifyContent: 'space-between', marginLeft: '16px', marginRight: '16px' }}>
                <div style={{ width: '43%', overflowY: 'scroll', height: 'calc(100vh - 170px)' }}>
                    <Stack style={{ background: 'white', borderRadius: 4, }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Thông tin cơ bản</h4>
                            <Input
                                label="Số điện thoại"
                                variant="standard" placeholder='Số điện thoại' value={(lead && lead.user) ? lead.user.username : ''}
                                disabled
                            />

                            <Input
                                label="Tên khách hàng"
                                variant="standard" placeholder='Tên khách hàng' value={(lead && lead.user) ? lead.user.full_name : ''}
                                disabled
                            />

                            <TextField
                                label="Email"
                                variant="standard" placeholder='Email' value={lead.email ?? ''}
                                onChange={(e) => setLead((old) => ({ ...old, email: e.target.value }))}
                            />

                            <TextField
                                id="outlined-textarea"
                                variant="standard"
                                label="Ghi chú"
                                multiline
                                rows={4}
                                value={lead.note ?? ''}
                                onChange={(e) => setLead((old) => ({ ...old, note: e.target.value }))}
                            />
                        </Stack>

                        <Stack direction='row' sx={{ gap: '32px', justifyContent: 'space-between' }}>
                            <Button sx={{ flex: 1 }} onClick={openContact} variant='contained' color='info'>
                                Chi tiết khách hàng
                            </Button>
                            <Button sx={{ flex: 1 }} onClick={handleSave} variant='contained' color='success' disabled={lead.status > 2 && auth.account_type === 5}>
                                Lưu thông tin
                            </Button>
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Thông tin sale</h4>
                            <Stack spacing={1}>
                                <InputLabel id="shop-select-label">Cửa hàng</InputLabel>
                                <Select
                                    labelId="shop-select-label"
                                    id="shop-select-select"
                                    value={shop}
                                    label="Cửa hàng"
                                    onChange={onChangeShop}
                                    disabled={lead.status > 2 && auth.account_type === 5}
                                >
                                    {(shops ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack spacing={1}>
                                <InputLabel id="sale1-select-label">Sale chính</InputLabel>
                                <Select

                                    labelId="sale1-select-label"
                                    id="sale1-select-select"
                                    value={lead && lead.sale1 ? lead.sale1.id : null}
                                    label="Sale chính"
                                    disabled={!(auth.account_type === 2 || auth.account_type === 3) || (lead.status > 2 && auth.account_type === 5)}
                                    onChange={onChangeSale1}
                                >
                                    <MenuItem key={-1} value={-1}>{'Không có sale'}</MenuItem>
                                    {(staffs ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack spacing={1} direction='row' alignItems='center'>
                                <InputLabel id="sale1-select-label">Sale chỉ định</InputLabel>
                                <Checkbox
                                    checked={lead && lead.force_assign ? lead.force_assign : false}
                                    inputProps={{ 'aria-label': 'Sale chỉ định' }}
                                    onChange={(e) => setForceAssign(e.target.checked)}
                                />
                            </Stack>

                            <Stack spacing={1}>
                                <InputLabel id="sale2-select-label">Sale phụ</InputLabel>
                                <Select
                                    labelId="sale2-select-label"
                                    id="sale2-select-select"
                                    value={lead && lead.sale2 ? lead.sale2.id : null}
                                    label="Sale phụ"
                                    onChange={onChangeSale2}
                                    disabled={lead.status > 2 && auth.account_type === 5}
                                >
                                    <MenuItem key={-1} value={-1}>{'Không có sale'}</MenuItem>
                                    {(staffs ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack spacing={1}>
                                <InputLabel id="telesale-select-label">Telesale</InputLabel>
                                <Select
                                    labelId="telesale-select-label"
                                    id="telesale-select-select"
                                    value={lead && lead.telesale ? lead.telesale.id : null}
                                    label="Telesale"
                                    onChange={onChangeTelesale}
                                    disabled={lead.status > 2 && auth.account_type === 5}
                                >
                                    <MenuItem key={-1} value={-1}>{'Không có sale'}</MenuItem>
                                    {(staffs ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Chi tiết khách hàng</h4>
                            <Stack spacing={1}>
                                <InputLabel id="shop-select-label">Loại khách hàng</InputLabel>
                                <Select
                                    labelId="type-select-label"
                                    id="type-select-select"
                                    value={lead ? lead.customer_type : null}
                                    label="Loại khách hàng"
                                    onChange={onChangeCustomerType}
                                >
                                    <MenuItem key={-1} value={-1}>{'Bỏ phân loại'}</MenuItem>
                                    {(customerTypes ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack spacing={1}>
                                <InputLabel id="sale1-select-label">Phân loại dịch vụ</InputLabel>
                                <Select

                                    labelId="service-type-select-label"
                                    id="service-type-select-select"
                                    value={lead ? lead.service_type : null}
                                    label="Phân loại dịch vụ"
                                    onChange={onChangeServiceType}
                                >
                                    <MenuItem key={-1} value={-1}>{'Bỏ phân loại'}</MenuItem>
                                    {(serviceTypes ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack spacing={1}>
                                <InputLabel id="sale2-select-label">Hạng khách hàng</InputLabel>
                                <Select
                                    labelId="customer-priority-select-label"
                                    id="customer-priority-select-select"
                                    value={lead ? lead.customer_priority : null}
                                    label="Hạng khách hàng"
                                    onChange={onChangeCustomerPriority}
                                >
                                    <MenuItem key={-1} value={-1}>{'Không có hạng'}</MenuItem>
                                    {(customerPriorities ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Chặng chuyển đổi</h4>
                            <Stack spacing={1}>
                                <InputLabel id="status-select-label">Chặng chuyển đổi hiện tại</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select-select"
                                    value={lead.status}
                                    label="Cửa hàng"
                                    onChange={updateStatus}
                                    disabled={lead.status > 2 && auth.account_type === 5}
                                >
                                    {(LEAD_STATUS_LIST ?? []).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Input
                                label="Tình trạng cụ thể hoặc lý do từ chối"
                                variant="standard" placeholder='Tình trạng cụ thể hoặc lý do từ chối' value={(lead && lead.user) ? lead.user.full_name : ''}
                                disabled
                            />
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Ghi chú của nhân viên chăm sóc khách hàng</h4>
                            <TextField
                                id="outlined-textarea"
                                variant="standard"
                                label="Ghi chú"
                                multiline
                                rows={4}
                                value={lead.note ?? ''}
                                onChange={(e) => setLead((old) => ({ ...old, note: e.target.value }))}
                            />
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Thông tin nguồn</h4>
                            <Stack spacing={1}>
                                <InputLabel id="source-select-label">Nguồn khách</InputLabel>
                                <Select
                                    labelId="source-select-label"
                                    id="source-select"
                                    value={source}
                                    label="Nguồn khách"
                                    onChange={onChangeSource}
                                    disabled={lead.status > 2 && auth.account_type === 5}
                                >
                                    {(sources ?? []).map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                            <TextField
                                id="outlined-textarea"
                                variant="standard"
                                label="Chi tiết nguồn khách hàng"
                                multiline
                                rows={4}
                                value={lead.source_detail ?? ''}
                                onChange={(e) => setLead((old) => ({ ...old, source_detail: e.target.value }))}
                            />
                            <TextField
                                id="outlined-textarea"
                                variant="standard"
                                label="Khu vực"
                                multiline
                                rows={4}
                                value={lead.customer_address ?? ''}
                                onChange={(e) => setLead((old) => ({ ...old, customer_address: e.target.value }))}
                            />
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Hẹn vào ZOOM với bác sĩ</h4>
                            <Stack spacing={1}>
                                <InputLabel id="source-select-label">Tình trạng hẹn vào ZOOM</InputLabel>
                                <Select
                                    labelId="source-select-label"
                                    id="source-select"
                                    value={lead.zoom_accept}
                                    label="Nguồn khách"
                                    onChange={onChangeSource}
                                    disabled={lead.status > 2 && auth.account_type === 5}
                                >
                                    {(sources ?? []).map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                            <TextField
                                id="outlined-textarea"
                                variant="standard"
                                label="Chi tiết lý do từ chối"
                                multiline
                                rows={4}
                                value={lead.zoom_deny_reason}
                                onChange={(e) => setLead((old) => ({ ...old, zoom_deny_reason: e.target.value }))}
                            />
                        </Stack>
                    </Stack>

                    <Stack style={{ background: 'white', borderRadius: 4, marginTop: '32px' }}>
                        <Stack style={{ margin: '16px' }} spacing={2}>
                            <h4>Thông tin về thời gian</h4>
                            <Input
                                label="Thời gian cập nhật cuối cùng"
                                variant="standard" placeholder='Thời gian cập nhật cuối cùng' value={dayjs(lead.updated_time).format('HH:mm DD/MM/YYYY')}
                                disabled
                            />
                            <Input
                                label="Thời gian tạo hồ sơ"
                                variant="standard" placeholder='Thời gian tạo hồ sơ' value={dayjs(lead.created_time).format('HH:mm DD/MM/YYYY')}
                                disabled
                            />
                        </Stack>
                    </Stack>
                </div>
                <div style={{ width: '55%', overflowX: 'scroll', background: 'white', borderRadius: 4, height: 'calc(100vh - 170px)'}}>
                    {/* <Stack padding="8px 8px" flexDirection='row' width='100%' justifyContent='center' alignItems='center' gap="32px">
                        {
                            lead.status === 0 &&
                            <Button onClick={() => updateStatus(1)} variant='contained' color='warning'>
                                Telesale tư vấn
                            </Button>
                        }
                        {
                            lead.status === 1 &&
                            <>
                                <Button onClick={() => updateStatus(0)} variant='text' color='error' size='small' style={{ fontSize: '12px' }}>
                                    Khách chưa có tiến trình
                                </Button>
                                <Button onClick={() => updateStatus(2)} variant='contained' color='warning'>
                                    Chuyển cho Sale
                                </Button>
                            </>
                        }
                        {
                            lead.status === 2 &&
                            <>
                                <Button disabled={lead.status > 2 && auth.account_type === 5} onClick={() => updateStatus(1)} variant='text' color='error' style={{ fontSize: '12px' }}>
                                    Khách không đồng ý hẹn
                                </Button>
                                <Button disabled={lead.status > 2 && auth.account_type === 5} onClick={() => updateStatus(3)} variant='contained' color='warning'>
                                    Hẹn khách đến tư vấn
                                </Button>
                            </>
                        }
                        {
                            lead.status === 3 &&
                            <>
                                <Button disabled={lead.status > 2 && auth.account_type === 5} onClick={() => updateStatus(1)} variant='text' color='error' style={{ fontSize: '12px' }}>
                                    Khách không chốt
                                </Button>
                                <Button disabled={lead.status > 2 && auth.account_type === 5} onClick={() => updateStatus(4)} variant='contained' color='success'>
                                    Khách chốt mua liệu trình
                                </Button>
                            </>
                        }
                    </Stack>
                    <Divider /> */}
                    <Tabs value={selectedTab} variant="scrollable" onChange={handleChangeTab}
                        scrollButtons="auto" style={{ width: '100%' }}>
                        <Tab value={0} label={
                            <Badge badgeContent={leadServices.length} color='error'>
                                {`Liệu trình quan tâm${leadServices.length > 0 ? '__' : ''}`}
                            </Badge>
                        } style={{ fontSize: '12px' }} />

                        <Tab value={1} label={
                            <Badge badgeContent={leadTransactions.filter((item) => item.type === 3).length} color='error'>
                                {`Lịch tư vấn${leadTransactions.filter((item) => item.type === 3).length > 0 ? '__' : ''}`}
                            </Badge>
                        } style={{ fontSize: '12px' }} />
                        <Tab value={2} label={
                            <Badge badgeContent={leadOrders.length} color='error'>
                                {`Đơn hàng${leadOrders.length > 0 ? '__' : ''}`}
                            </Badge>
                        } style={{ fontSize: '12px' }} />
                        <Tab value={3} label={
                            <Badge badgeContent={leadTransactions.filter((item) => item.type === 2).length} color='error'>
                                {`Liên lạc${leadTransactions.filter((item) => item.type === 2).length > 0 ? '__' : ''}`}
                            </Badge>
                        } style={{ fontSize: '12px' }} />
                        <Tab value={4} label={
                            <Badge badgeContent={leadTransactions.filter((item) => item.type === 1).length} color='error'>
                                {`Tiến trình sale${leadTransactions.filter((item) => item.type === 1).length > 0 ? '__' : ''}`}
                            </Badge>
                        } style={{ fontSize: '12px' }} />
                    </Tabs>
                    <CustomTabPanel value={selectedTab} index={0}>
                        <Stack spacing={3}>
                            <Button disabled={lead.status > 2 && auth.account_type === 5} variant='contained' onClick={() => setAddServiceModal(true)}>Thêm dịch vụ</Button>
                            <AddServiceModal
                                open={addServiceModal}
                                handleAdd={onAddService}
                                services={services}
                                handleClose={() => {
                                    setAddServiceModal(false)
                                }}
                            />
                            {
                                leadServices.map((item, index) => (
                                    <ServiceSuggestItem
                                        service_name={item.service_name}
                                        service={item.service}
                                        staff={item.staff}
                                        note={item.note}
                                        key={index.toString()}
                                        onRemove={() => onRemoveService(item, index)}
                                    />
                                ))
                            }
                        </Stack>
                    </CustomTabPanel>


                    <CustomTabPanel value={selectedTab} index={1}>
                        <Stack spacing={3}>
                            <Button disabled={lead.status > 2 && auth.account_type === 5} variant='contained' onClick={() => setAddScheduleModal(true)}>Đặt lịch tư vấn</Button>
                            <AddScheduleModal
                                open={addScheduleModal}
                                handleAdd={onAddSchedule}
                                handleClose={() => {
                                    setAddScheduleModal(false)
                                }}
                            />
                            {
                                leadTransactions.filter((item) => item.type === 3).map((item, index) => (
                                    <ScheduleItem
                                        {...item}
                                        current_staff={auth}
                                        note={item.note}
                                        key={index.toString()}
                                    />
                                ))
                            }
                        </Stack>
                    </CustomTabPanel>


                    <CustomTabPanel value={selectedTab} index={2}>
                        <Stack spacing={3}>
                            <Button disabled={lead.status > 2 && auth.account_type === 5} variant='contained' onClick={() => setAddOrderModal(true)}>Tạo đơn hàng</Button>
                            <AddOrderModal
                                open={addOrderModal}
                                onCreateOrder={onCreateOrder}
                                services={services}
                                user_id={(lead && lead.user) ? lead.user.id : null}
                                handleClose={() => {
                                    setAddOrderModal(false)
                                }}
                            />
                            <AddPaymentModal
                                open={addPaymentModal !== null}
                                onAddPayment={onAddPayment}
                                order={addPaymentModal}
                                handleClose={() => {
                                    setShowAddPaymentModal(null)
                                }}
                            />
                            {changeComissionModal !== null &&
                                <AddComissionModal
                                    open={changeComissionModal !== null}
                                    onChangeComission={onChangeComission}
                                    payment={changeComissionModal}
                                    handleClose={() => {
                                        setShowChangeComissionModal(null)
                                    }}
                                />
                            }
                            {changeOrderDateModal !== null &&
                                <ChangeOrderDateModal
                                    open={changeOrderDateModal !== null}
                                    handleUpdate={changeOrderDate}
                                    order={changeOrderDateModal}
                                    handleClose={() => {
                                        setChangeOrderDateModal(null)
                                    }}
                                />
                            }
                            {updateOrderModal !== null &&
                                <UpdateOrderModal
                                    open={updateOrderModal !== null}
                                    handleUpdate={updateOrderItem}
                                    order={updateOrderModal}
                                    handleClose={() => {
                                        setUpdateOrderModal(null)
                                    }}
                                />
                            }
                            {
                                leadOrders.map((item, index) => (
                                    <OrderItem
                                        {...item}
                                        current_staff={auth}
                                        note={item.note}
                                        key={index.toString()}
                                        changeOrderDate={() => setChangeOrderDateModal(item)}
                                        onCancelOrder={() => onCancelOrder(item)}
                                        onCancelPayment={(payment) => onCancelPayment(item, payment)}
                                        onAddPayment={() => setShowAddPaymentModal(item)}
                                        onChangeComission={(payment) => setShowChangeComissionModal({...payment, lead_order: item})}
                                        onChangeSale1={(id) => onChangeOrderSale(item.id, 'sale1', id)}
                                        onChangeSale2={(id) => onChangeOrderSale(item.id, 'sale2', id)}
                                        onChangeTelesale={(id) => onChangeOrderSale(item.id, 'telesale', id)}
                                        onUpdateOrder={(order) => setUpdateOrderModal(order)}
                                    />
                                ))
                            }
                        </Stack>
                    </CustomTabPanel>

                    <CustomTabPanel value={selectedTab} index={3}>
                        <Stack spacing={3}>
                            <Button disabled={lead.status > 2 && auth.account_type === 5} variant='contained' onClick={() => setAddConnectionModal(true)}>Thêm lịch sử liên hệ</Button>
                            <AddCommunicationModal
                                open={addConnectionModal}
                                handleAdd={onAddConnection}
                                services={services}
                                handleClose={() => {
                                    setAddConnectionModal(false)
                                }}
                            />
                            {
                                leadTransactions.filter((item) => item.type === 2).map((item, index) => (
                                    <ConnectionItem
                                        {...item}
                                        current_staff={auth}
                                        note={item.note}
                                        key={index.toString()}
                                    />
                                ))
                            }
                        </Stack>
                    </CustomTabPanel>


                    <CustomTabPanel value={selectedTab} index={4}>
                        <Stack spacing={3}>
                            {
                                leadTransactions.filter((item) => item.type === 1).map((item, index) => (
                                    <TransactionItem
                                        {...item}
                                        current_staff={auth}
                                        note={item.note}
                                        key={index.toString()}
                                    />
                                ))
                            }
                        </Stack>
                    </CustomTabPanel>
                </div>
            </div>
        </div>
    )
}

export default Screen