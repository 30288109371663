import React from 'react'
import {Typography} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom';
import { PERMISSION_DICT } from '../../../../utils/utils';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PermissionItem = ({ staff }) => {
    const history = useHistory()

    const openDetail = () => {
        history.push(`/management/permission/${staff.username}`)
    }

    return (
        <StyledTableRow
            key={staff.username}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            
        >
            <TableCell component="th" scope="row">
                {staff.username}
            </TableCell>
            <TableCell align="right">{staff.full_name}</TableCell>
            <TableCell align="right">
            <Typography sx={{fontSize: '12px'}}>
                {staff.permissions.map((item) => (PERMISSION_DICT[item] ?? '').length > 0 ? `${PERMISSION_DICT[item] ?? ''}, ` : '')}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
                    <Button onClick={openDetail} color="success">Thay đổi</Button>
                </div>
            </TableCell>
        </StyledTableRow>
    )
}

export default PermissionItem