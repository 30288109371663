import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import { convertMoney, CUSTOMER_PRIOTIES } from '../../../../utils/utils';
import dayjs from 'dayjs';

const ContactItem = ({ customer }) => {
  const history = useHistory()
  const openDetail = () => {
    history.push(`/crm/contact/${customer.username}`)
  }

  return (
    <TableRow
      key={customer.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row" onClick={openDetail} sx={{cursor: 'pointer'}}>
        {customer.full_name}
      </TableCell>
      <TableCell align="right" onClick={openDetail} sx={{color: 'blue', cursor: 'pointer', textDecorationLine: 'underline'}}>{customer.username}</TableCell>
      <TableCell align="right">{CUSTOMER_PRIOTIES[customer.customer_priority ?? 0]}</TableCell>
      <TableCell align="right">{customer.booking_remain ?? 0}</TableCell>
      <TableCell align="right">{customer.current_service_name ?? ''}</TableCell>
      <TableCell align="right">{customer.last_action_time ? dayjs(customer.last_action_time).format('HH:mm DD/MM/YYYY') : ''}</TableCell>
      <TableCell align="right">{customer.last_visit_time ? dayjs(customer.last_visit_time).format('HH:mm DD/MM/YYYY') : ''}</TableCell>
    </TableRow>
  )
}

export default ContactItem