import { Stack, Box, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Table, TablePagination, TableSortLabel, styled } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionsBar from '../../../components/ActionsBar';
import { FilterAlt } from '@mui/icons-material'
import TextField from '@mui/material/TextField/TextField';
import MemberItem from './components/MemberItem';
import Paper from '@mui/material/Paper'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses, } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAtom, useAtomValue } from 'jotai';
import { authAtom, customersAtom, staffsAtom, tokenAtom } from '../../../actions';
import axios from 'axios';
import { visuallyHidden } from '@mui/utils'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { hasPermission } from '../../../utils/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
    },
}));

const headCells = [
    {
        id: 'full_name',
        paddingRight: false,
        disablePadding: false,
        label: 'Họ tên',
    },
    {
        id: 'username',
        paddingRight: true,
        disablePadding: false,
        label: 'Số điện thoại',
    },
    {
        id: 'totalOrder',
        paddingRight: true,
        disablePadding: false,
        label: 'Số liệu trình đã mua',
    },
    {
        id: 'totalPrice',
        paddingRight: true,
        disablePadding: false,
        label: 'Tổng chi',
    },
    {
        id: 'staffs',
        paddingRight: true,
        disablePadding: false,
        label: 'Nhân sự hỗ trợ',
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.paddingRight ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            sx={
                                {
                                    '&.MuiTableSortLabel-root': {
                                        color: 'white',
                                    },
                                    '&.MuiTableSortLabel-root:hover': {
                                        color: 'white',
                                    },
                                    '&.Mui-active': {
                                        color: 'white',
                                    },
                                    '& .MuiTableSortLabel-icon': {
                                        color: 'white !important',
                                    },
                                }
                            }
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const Screen = () => {
    const [customers, setCustomers] = useAtom(customersAtom)
    const [token] = useAtom(tokenAtom)
    const [loading, setLoading] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [staffs] = useAtom(staffsAtom)
    const [total, setTotal] = useState(0)
    const searchTimeout = useRef(null)
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('totalOrder');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [selectedStaff, setSelectedStaff] = useState(0)
    const { id: source_id } = useParams()
    const authUser = useAtomValue(authAtom)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event.target.value)
        setPage(0)
    }

    const getCustomers = useCallback(() => {
        console.log({ token })
        if (token) {
            setLoading(true)
            try {
                if (searchTimeout.current) {
                    clearTimeout(searchTimeout.current)
                    searchTimeout.current = null
                }

            } catch (error) {
                console.log({ error })
            }

            searchTimeout.current = setTimeout(() => {
                axios.post('/accounts/customers/customer_list/', {
                    keyword: keyword.replace(/#/g, ''), order, orderBy, offset: page * rowsPerPage, limit: rowsPerPage,
                    staff: selectedStaff > 0 ? selectedStaff : null,
                    source: source_id, leadSearch: true
                }, {
                })
                    .then((response) => {
                        console.log({ response })
                        if (response && response.data) {
                            setCustomers(response.data.customers ?? [])
                            setTotal(response.data.total ?? 0)
                        }
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log({ error })
                        setLoading(false)
                    })
            }, 500);
        }
    }, [token, keyword, setCustomers, order, orderBy, page, rowsPerPage, selectedStaff, source_id])

    useEffect(() => {
        getCustomers()
    }, [getCustomers, token])

    useEffect(() => {
        getCustomers()
    }, [getCustomers, keyword])

    const onAddCustomer = () => {

    }

    const staffOptions = staffs.map((staff) => ({ label: staff.full_name, id: staff.id }))
    staffOptions.unshift({ label: 'Tất cả', id: 0 })

    if(!(authUser && hasPermission(authUser, ['view_group', 'view_group_all']))) {
        return <Stack sx={{flex: 1, alignItems: 'center', justifyContent: 'center', margin: '32px'}}>Không có quyền truy cập</Stack>
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }} >
            <ActionsBar title='Danh sách khách hàng' subTitle={`Tìm thấy ${total} khách hàng`} />
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', marginLeft: '16px' }}>
                    <TextField
                        style={{ height: '36px', fontSize: '12px', color: 'black' }}
                        size='small' value={keyword}
                        onChange={handleChangeKeyword}
                        label="Tìm kiếm tên, sđt"
                    />
                </Box>
                {loading && <CircularProgress />}
                <Box sx={{ display: 'flex', marginRight: '16px' }}>
                    {
                        hasPermission(authUser, ['view_group_all']) && (
                            <FormControl sx={{ width: '180px' }} variant='standard'>
                                <InputLabel sx={{ fontSize: '12px' }}>Nhân viên</InputLabel>
                                <Select sx={{ fontSize: '12px' }}
                                    defaultValue={null} value={selectedStaff}
                                    onChange={(e) => setSelectedStaff(e.target.value)} label='Nhân viên'>
                                    {
                                        staffOptions.map((item) => {
                                            return (
                                                <MenuItem value={item.id}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                </Box>
            </Box>
            <div style={{ display: "flex", width: 'calc(100vw - 230px)', marginTop: '24px', padding: '16px' }}>
                <Paper style={{ padding: '16px' }}>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <TableContainer component={Paper} style={{ overflowY: 'scroll', width: 'calc(100vw - 250px)', overflowX: 'scroll', maxHeight: '60vh' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="data table" stickyHeader>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={total}
                            />
                            <TableBody>
                                {customers.map((customer) => (
                                    <MemberItem customer={customer} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </div>
    )
}

export default Screen